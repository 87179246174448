import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";

export default {
    computed: {
        oaEmpList: {
            get () {return this.$store.state.systemData.oaEmpList;}
        },
        user: {
            get () {return this.$store.getters.currentUser;}
        },
    },
    watch: {
        user: function () {
            this.initData();
        },
    },
    data () {
        return {
            condition: '',
            workStatus: 1,
            departId: ''
        }
    },
    mounted () {
        this.initData();
    },
    methods: {
        initData: function () {
            if (this.user && this.user.groupId) {
                this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                const depart = {};
                const str = this.user.groupId.split(',');
                this.departId = str[0];
                depart.recordId = this.departId;
                depart.workStatus = this.workStatus;
                depart.condition = this.condition;
                this.$store.dispatch("systemData/loadOaEmpList", depart);
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }
        },
    }
}
